export const crossdomainPlugWidth = {
  base: 'calc((90vw - (1*1rem))/1.2)',
  sm: 'calc((90vw - (1*1rem))/1.3)',
  md: 'calc((90vw - (2*1.5rem))/2)',
  lg: 'calc((82vw - (3*1.5rem))/2.3)',
  xl: 'calc((82vw - (4*1.5rem))/3.8)'
}

export const landscapePlugWidth = {
  base: 'calc((90vw - (1*1rem))/1.25)',
  sm: 'calc((90vw - (1*1rem))/1.25)',
  md: 'calc((90vw - (2*1.5rem))/3)',
  lg: 'calc((82vw - (3*1.5rem))/4)',
  xl: 'calc((82vw - (4*1.5rem))/4.25)'
}

export const squarePlugWidth = {
  base: 'calc((90vw - (1*1rem))/2)',
  sm: 'calc((90vw - (1*1rem))/2)',
  md: 'calc((90vw - (3*1.5rem))/4)',
  lg: 'calc((82vw - (4*1.5rem))/5)',
  xl: 'calc((82vw - (5*1.5rem))/6)'
}

export const landscapePlugWidthLarge = {
  base: 'calc((90vw - (1*1rem))/1.25)',
  sm: 'calc((90vw - (1*1rem))/1.25)',
  md: 'calc((90vw - (2*1.5rem))/2.25)',
  lg: 'calc((82vw - (3*1.5rem))/3.25)',
  xl: 'calc((82vw - (3*1.5rem))/4)'
}

export const landscapePlugWidthMedium = {
  base: 'calc((90vw - (1*1rem))/1.75)',
  sm: 'calc((90vw - (1*1rem))/1.7)',
  md: 'calc((90vw - (2*1.5rem))/3)',
  lg: 'calc((82vw - (3*1.5rem))/4)',
  xl: 'calc((82vw - (4*1.5rem))/4.25)'
}

export const landscapePlugWidthSmall = {
  base: 'calc((90vw - (1*1rem))/2)',
  sm: 'calc((90vw - (1*1rem))/2)',
  md: 'calc((90vw - (3*1.5rem))/4)',
  lg: 'calc((82vw - (4*1.5rem))/5)',
  xl: 'calc((82vw - (5*1.5rem))/6)'
}

export const squarePlugWidthLarge = {
  base: 'calc((90vw - (1*1rem))/1.5)',
  sm: 'calc((90vw - (1*1rem))/1.5)',
  md: 'calc((90vw - (2*1.5rem))/2.5)',
  lg: 'calc((82vw - (3*1.5rem))/3.5)',
  xl: 'calc((82vw - (3*1.5rem))/4)'
}

export const squarePlugWidthMedium = {
  base: 'calc((90vw - (1*1rem))/2)',
  sm: 'calc((90vw - (1*1rem))/2)',
  md: 'calc((90vw - (3*1.5rem))/4)',
  lg: 'calc((82vw - (4*1.5rem))/5)',
  xl: 'calc((82vw - (5*1.5rem))/6)'
}

export const squarePlugWidthSmall = {
  base: 'calc((90vw - (2*1rem))/3)',
  sm: 'calc((90vw - (2*1rem))/3)',
  md: 'calc((90vw - (4*1.5rem))/5)',
  lg: 'calc((82vw - (5*1.5rem))/6)',
  xl: 'calc((82vw - (6*1.5rem))/7)'
}
