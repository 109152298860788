import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import AspectRatioBox from '../../AspectRatioBox'
import CustomButton from '../../CustomButton'
import Flex from '../../Flex'
import Icon from '../../Icon'

const stringsMap = {
  left: {
    label: 'Forrige',
    iconId: 'nrk-arrow-left',
    testId: 'forrige'
  },
  right: {
    label: 'Neste',
    iconId: 'nrk-arrow-right',
    testId: 'neste'
  }
}

const ScrollerNavigation = ({ direction, onClick, disabled, ...props }) => {
  const { label, iconId, testId } = stringsMap[direction]

  return (
    <AspectRatioBox
      ratio={16 / 9}
      className="scroller-navigation"
      pos="absolute"
      left={direction === 'left' ? 2 : 'auto'}
      right={direction === 'right' ? 2 : 'auto'}
      top="8px"
      zIndex="1"
      pointerEvents="none"
      css={css`
        @media (hover: none) {
          display: none;
        }
      `}
      {...props}
    >
      <Flex align="center" justify={direction === 'left' ? 'flex-start' : 'flex-end'}>
        <CustomButton
          onClick={onClick}
          aria-label={label}
          tabIndex="-1"
          data-test={`navigering-${testId}`}
          disabled={disabled}
          pointerEvents="auto"
          _hover={{
            opacity: '1 !important'
          }}
          _active={{
            opacity: 1
          }}
          _disabled={{ opacity: '0 !important', pointerEvents: 'none' }}
        >
          <Icon iconId={iconId} />
        </CustomButton>
      </Flex>
    </AspectRatioBox>
  )
}

ScrollerNavigation.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

export default ScrollerNavigation
