import React, { useEffect } from 'react'
import { useCategories, useShouldLoadCategories } from './selectors'
import { useDispatch } from 'react-redux'
import { fetchCategories } from './actions'
import CategoryLinkBox from './CategoryLinkBox'
import PropTypes from 'prop-types'
import Scroller from '../../../RadioUI/Scroller'
import { landscapePlugWidthLarge } from '../../../RadioUI/Plugs/styles/plugWidths'

const CategoryList = ({ onClick = () => {}, ...restProps }) => {
  const shouldLoadCategories = useShouldLoadCategories()
  const dispatch = useDispatch()
  const categories = useCategories()

  useEffect(() => {
    if (shouldLoadCategories) {
      dispatch(fetchCategories())
    }
  }, [dispatch, shouldLoadCategories])

  return (
    <Scroller {...restProps}>
      {categories.map(({ id, title, image, url }, i) => {
        const last = categories.length - 1 === i
        return (
          <CategoryLinkBox
            key={id}
            to={url}
            title={title}
            src={image?.webImages?.[1]?.uri}
            w={landscapePlugWidthLarge}
            mr={{ base: last ? 0 : 4, md: last ? 0 : 6 }}
            onClick={() => {
              onClick({ id, title, image, url })
            }}
          />
        )
      })}
    </Scroller>
  )
}

CategoryList.propTypes = {
  onClick: PropTypes.func
}

export default CategoryList
