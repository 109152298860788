import React from 'react'
import PropTypes from 'prop-types'
import {
  CHANNEL,
  EPISODE,
  LINK,
  PAGE,
  PODCAST,
  PODCAST_EPISODE,
  PODCAST_SEASON,
  PROGRAM,
  SERIES,
  STANDALONE_PROGRAM
} from '../../../pages/FrontPage/helpers/plugTypes'
import PseudoBox from '../../PseudoBox'
import { Link } from 'react-router-dom'

const noop = () => {}

const PlugLink = ({ to, type, onClick = noop, children, ...restProps }) => {
  if (type === LINK) {
    const url = new URL(to)
    if (url.hostname === 'radio.nrk.no') {
      return (
        <PseudoBox
          as={Link}
          to={url.pathname}
          onClick={() => {
            onClick()
            window.scrollTo({ top: 0 })
          }}
          {...restProps}
        >
          {children}
        </PseudoBox>
      )
    }
    return (
      <PseudoBox as="a" href={to} onClick={onClick} target="_blank" rel="noopener noreferrer" {...restProps}>
        {children}
      </PseudoBox>
    )
  } else if (type === PAGE) {
    return (
      <PseudoBox
        as={Link}
        to={to}
        onClick={() => {
          onClick()
          window.scrollTo({ top: 0 })
        }}
        {...restProps}
      >
        {children}
      </PseudoBox>
    )
  }
  return (
    <PseudoBox as={Link} to={to} onClick={onClick} {...restProps}>
      {children}
    </PseudoBox>
  )
}

PlugLink.propTypes = {
  type: PropTypes.oneOf([
    PODCAST,
    PODCAST_EPISODE,
    SERIES,
    EPISODE,
    CHANNEL,
    STANDALONE_PROGRAM,
    PODCAST_SEASON,
    PROGRAM,
    LINK,
    PAGE
  ]),
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

export default PlugLink
