import React from 'react'
import PropTypes from 'prop-types'
import AspectRatioBox from '../../../../RadioUI/AspectRatioBox'
import Box from '../../../../RadioUI/Box'
import PseudoBox from '../../../../RadioUI/PseudoBox'

const CategoryLinkImage = ({ src }) => {
  return (
    <AspectRatioBox ratio={16 / 9} width="full" className="CategoryLinkImage">
      <Box
        overflow="hidden"
        background="linear-gradient(225deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
        borderRadius="6px 6px 0% 0%"
      >
        <PseudoBox as="img" w="100%" h="auto" borderRadius="6px 6px 0% 0%" src={src} />
      </Box>
    </AspectRatioBox>
  )
}

CategoryLinkImage.propTypes = {
  src: PropTypes.string
}

export default CategoryLinkImage
