import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import useShallowSelector from '../../../components/hooks/useShallowSelector'

const categoriesRootSelector = state => state.categories

export const categoriesSelector = createSelector([categoriesRootSelector], categoriesRoot => {
  if (!categoriesRoot || !Array.isArray(categoriesRoot.pages)) return []

  return categoriesRoot.pages.map(({ id, title, image }) => {
    return { id, title, snapshotId: id, type: 'desked-page', image, url: `/kategori/${id}` }
  })
})

const shouldLoadCategories = createSelector([categoriesRootSelector], categoriesRoot => {
  const { loading, pages, error } = categoriesRoot

  return !loading && !pages && !error
})

export const useShouldLoadCategories = () => {
  const shouldLoad = useSelector(shouldLoadCategories)
  return shouldLoad
}

export const useCategories = () => {
  const categories = useShallowSelector(categoriesSelector)
  return categories
}
