import React from 'react'
import PropTypes from 'prop-types'
import Box from '../../../../RadioUI/Box'
import { useHistory } from 'react-router-dom'
import CategoryLinkImage from './CategoryLinkImage'
import PseudoBox from '../../../../RadioUI/PseudoBox'
import { initHoverColorStyles } from '../../../../RadioUI/Plugs/styles/hoverColorStyles'
import { focusVisibleStyles } from '../../../../RadioUI/Plugs/styles/focusStyles'
import { useColors } from '../../../../RadioUI/theme/Color'
import PlugLink from '../../../../RadioUI/Plug/PlugLink/PlugLink'
import Text from '../../../../RadioUI/Text'
import { css } from '@emotion/react'

const CategoryLinkBox = ({ to, title, src, onClick = () => {}, ...restProps }) => {
  const history = useHistory()
  const hoverColorStyles = initHoverColorStyles(useColors().main, '.LandscapePlugTitle')

  return (
    <PseudoBox position="relative" flex="0 0 auto" bg={useColors().main.mediumLight10} borderRadius="md" {...restProps}>
      <PlugLink
        to={to}
        onClick={event => {
          event.preventDefault()
          window.scrollTo({ top: 0 })
          history.push(to)
          onClick()
        }}
        pos="relative"
        d="block"
        css={
          ([hoverColorStyles, focusVisibleStyles],
          css`
            &:hover,
            &:focus,
            &:focus-within,
            &:focus-visible,
            &:active {
              background-color: ${useColors().main.mediumLight20};
              outline: none;
            }
          `)
        }
        {...restProps}
      >
        {src && <CategoryLinkImage src={src} />}
        <Box padding="10px 10px 10px 0px">
          <Text
            as="h3"
            variant="subheadRegular"
            padding="0px 8px 0px 8px"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {title}
          </Text>
        </Box>
      </PlugLink>
    </PseudoBox>
  )
}

CategoryLinkBox.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  src: PropTypes.string,
  onClick: PropTypes.func
}

export default CategoryLinkBox
