import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import CoreScroll from '@nrk/core-scroll/jsx'
import { css } from '@emotion/react'
import Box from '../Box'
import ScrollerNavigation from './ScrollerNavigation'

const scrollStyles = css`
  .core-scroll {
    display: flex;
    padding: 8px 0px;
    .gutter {
      width: 5%;
      flex: 0 0 auto;
    }
    & > a:first-of-type,
    & > div:first-of-type:not(.gutter),
    & > article:first-of-type:not(.gutter),
    & > button:first-of-type {
      margin-left: 5%;
    }
    & > a:last-of-type,
    & > div:last-of-type:not(.gutter),
    & > article:last-of-type:not(.gutter),
    & > button:last-of-type {
      margin-right: 0 !important;
    }
    & ~ .scroller-navigation button {
      opacity: 0;
      transition: all 0.2s ease;
    }
    &:hover ~ .scroller-navigation button:not(:disabled) {
      opacity: 1;
    }
  }

  @media screen and (min-width: 62em) {
    .core-scroll {
      & > a:first-of-type,
      & > div:first-of-type:not(.gutter),
      & > article:first-of-type:not(.gutter),
      & > button:first-of-type {
        margin-left: 9%;
      }
      .gutter {
        width: 9%;
      }
    }
  }
`
const noop = () => {}

const Scroller = forwardRef(
  ({ children, plugWidth = 325, onScrollChange = noop, onScrollNavigationClick = noop, ...restProps }, ref) => {
    const [scrollButtonState, setScrollButtonState] = useState({})
    const handleScrollChange = event => {
      setScrollButtonState({
        left: event.target.scrollLeft > 0 ? () => event.target.scroll('left') : undefined,
        right: event.target.scrollRight > 0 ? () => event.target.scroll('right') : undefined
      })
      onScrollChange(event)
    }
    const { left, right } = scrollButtonState
    return (
      <Box className="Scroller" overflow="hidden" position="relative" css={scrollStyles} {...restProps}>
        <CoreScroll ref={ref} className="core-scroll" onScrollChange={handleScrollChange}>
          {children}
          <div className="gutter" />
        </CoreScroll>

        <ScrollerNavigation
          disabled={!left}
          onClick={() => {
            left()
            onScrollNavigationClick()
          }}
          direction="left"
          width={plugWidth}
        />
        <ScrollerNavigation
          disabled={!right}
          onClick={event => {
            right()
            onScrollNavigationClick()
          }}
          direction="right"
          width={plugWidth}
        />
      </Box>
    )
  }
)

Scroller.displayName = 'Scroller'

Scroller.propTypes = {
  plugWidth: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  children: PropTypes.node,
  onScrollChange: PropTypes.func,
  onScrollNavigationClick: PropTypes.func,
  css: PropTypes.shape({})
}

export default Scroller
